import styled from 'styled-components'

export const StyledProjectCategories = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px;
  padding-top: 32px;
  padding-bottom: 48px;
`

export const StyledButtonCategory = styled.li`
  button {
    position: relative;
    text-transform: uppercase;
    font-size: 85%;
    cursor: pointer;
    margin-bottom: 8px;
    &:hover {
      span {
        width: calc(100% - 16px);
      }
    }
    span {
      height: 1px;
      background: black;
      position: absolute;
      /* width: 100%; */
      left: 0;
      margin-left: 8px;
      right: 0;
      bottom: -1px;
      width: ${props => (props.isActive ? 'calc(100% - 16px)' : '0')};
      transition: width 0.5s ease-in-out;
    }
  }
`
