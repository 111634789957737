import PropTypes from 'prop-types'
import React from 'react'

import {
  StyledProjectCategories,
  StyledButtonCategory,
} from './project-categories.styled'

export const ProjectCategories = ({ active, options, onPressCategory }) => (
  <StyledProjectCategories>
    {options.map(type => {
      const isActive = type === active
      const onClick = () => onPressCategory(type)
      return (
        <StyledButtonCategory isActive={isActive} key={type}>
          <button onClick={onClick} type="button">
            {type}
            <span />
          </button>
        </StyledButtonCategory>
      )
    })}
  </StyledProjectCategories>
)

ProjectCategories.propTypes = {
  active: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string),
  onPressCategory: PropTypes.func.isRequired,
}

ProjectCategories.defaultProps = {
  options: [],
}
