import React from 'react'

import { ProjectSingle } from './project-single'
import { StyledGridContainer } from './projects.styled'

export const ProjectsGrid = ({ projects }) => (
  <StyledGridContainer enterAnimation="fade" leaveAnimation="none">
    {projects.map(project => (
      <ProjectSingle {...project} key={project.slug} project={project} />
    ))}
  </StyledGridContainer>
)
