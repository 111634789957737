import PropTypes from 'prop-types'
import React from 'react'
import { Hover } from 'react-powerplug'
import { Text } from 'rebass'
import {
  StyledProjectContainer,
  StyledLink,
  StyledImage,
  StyledEffect,
} from './project-single.styled'

export class ProjectSingle extends React.Component {
  render() {
    const { project } = this.props
    const { title, location, year, coverImage, slug } = project

    return (
      <StyledProjectContainer>
        <Hover>
          {({ hovered, bind }) => (
            <React.Fragment>
              <StyledLink to={`${slug}`} hovered={hovered} {...bind}>
                <StyledEffect hovered={hovered} />
                <Text as="h2" fontSize={3} fontWeight="400">
                  {title}
                </Text>
                <Text as="p" fontSize={1}>
                  {year}
                </Text>
                <Text as="p" fontSize={1}>
                  {location}
                </Text>
              </StyledLink>
              <StyledImage
                hovered={hovered}
                fluid={coverImage.fluid}
                backgroundColor="#eeeeee"
              />
            </React.Fragment>
          )}
        </Hover>
      </StyledProjectContainer>
    )
  }
}

ProjectSingle.propTypes = {
  project: PropTypes.shape({
    date: PropTypes.string,
    location: PropTypes.shape({ lon: PropTypes.number, lat: PropTypes.number }),
    slug: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
}
