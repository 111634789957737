import { Location, navigate } from '@reach/router'
import { graphql } from 'gatsby'
import * as queryString from 'query-string'
import React from 'react'
import { Value } from 'react-powerplug'

import SEO from '../components/SEO'
import { ProjectsTemplate } from '../templates/projects'

const ProjectsPage = ({ data }) => (
  <Location>
    {({ location }) => {
      const params = queryString.parse(location.search)

      const projects = data.allContentfulProyectos.edges.map(
        proyecto => proyecto.node
      )
      const categoryTitles = data.allContentfulCategorias.edges
        .filter(categoria => categoria.node.proyectos !== null)
        .map(categoria => categoria.node.title)

      const allCategoryTitle = 'Todos'
      categoryTitles.unshift(allCategoryTitle)

      const {
        metaTitle,
        metaDescription: { metaDescription },
      } = data.contentfulPaginas

      return (
        <React.Fragment>
          <SEO title={metaTitle} description={metaDescription} isPage />
          <Value
            initial={
              categoryTitles.find(category => category === params.categoria) ||
              allCategoryTitle
            }
          >
            {({ value, set }) => {
              const categories = {
                options: categoryTitles,
                active: value,
                onPressCategory: category => {
                  set(category)
                  if (category === 'Todos') {
                    navigate(`/proyectos`)
                  } else {
                    navigate(`?categoria=${category}`)
                  }
                },
              }

              const filter = projectsToFilter =>
                projectsToFilter.filter(project => {
                  if (categories.active === allCategoryTitle) return true
                  return categories.active === project.category.title
                })

              return (
                <ProjectsTemplate
                  projects={filter(projects)}
                  categories={categories}
                />
              )
            }}
          </Value>
        </React.Fragment>
      )
    }}
  </Location>
)

export const query = graphql`
  query {
    allContentfulProyectos(sort: { fields: fechaDePublicacin, order: DESC }) {
      edges {
        node {
          slug
          title
          location
          category {
            title
          }
          year
          coverImage {
            title
            fluid(maxWidth: 1000) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
    allContentfulCategorias {
      edges {
        node {
          title
          proyectos {
            id
          }
        }
      }
    }
    contentfulPaginas(slug: { eq: "proyectos" }) {
      metaTitle
      metaDescription {
        metaDescription
      }
    }
  }
`

export default ProjectsPage
