import { Link } from 'gatsby'
import Img from 'gatsby-image'

import styled from 'styled-components'

export const StyledProjectContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

export const StyledImage = styled(Img)`
  opacity: ${props => (props.hovered ? 0.15 : 1)};
  transition: all 0.8s ease;
  transform: ${props => (props.hovered ? 'scale(1.1)' : null)};
  height: 100%;
`

export const StyledLink = styled(Link)`
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  opacity: ${props => (props.hovered ? 1 : 0)};
  transition: all 1s ease;
  text-decoration: none;
  h2 {
    color: ${props => props.theme.colors.gray};
    text-transform: uppercase;
    padding-bottom: 16px;
    font-size: 120%;
    flex: 1;
  }
  p {
    margin-top: 8px;
    color: ${props => props.theme.colors.gray};
  }
  :before,
  :after {
    width: 1px;
    height: 0;
    background-color: ${props => props.theme.colors.gray};
    z-index: 10;
    transition: all 0.4s;
    content: '';
    display: block;
    position: absolute;
  }
  :before {
    height: ${props => (props.hovered ? '100%' : 0)};
    right: 0;
    top: 0;
  }
  :after {
    height: ${props => (props.hovered ? '100%' : 0)};
    left: 0;
    bottom: 0;
  }
`
export const StyledEffect = styled.div`
  :before,
  :after {
    content: '';
    display: block;
    position: absolute;
    background-color: ${props => props.theme.colors.gray};
    z-index: 10;
    transition: all 0.4s;
  }
  :before {
    height: 1px;
    width: ${props => (props.hovered ? '100%' : 0)};
    right: 0;
    bottom: 0;
  }
  :after {
    height: 1px;
    width: ${props => (props.hovered ? '100%' : 0)};
    left: 0;
    top: 0;
  }
`

export const StyledH2 = styled.h2`
  font-weight: 400 !important;
  font-size: 140% !important;
`
