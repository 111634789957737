import FlipMove from 'react-flip-move'
import styled from 'styled-components'
import { Container } from '../../components/styled'

export const StyledFirstSection = styled(Container)`
  padding-top: 30vh;
`

export const StyledGridContainer = styled(FlipMove)`
  display: flex;
  flex-wrap: wrap;
  > div {
    margin-bottom: ${props => props.theme.space[3]}px;
  }
  @media (min-width: ${props => props.theme.breakpoints[0]}) {
    > div {
      margin-bottom: initial;
    }
    /* Supports Grid */
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    grid-auto-rows: minmax(300px, auto);
    grid-gap: ${props => props.theme.space[3]}px;
  }
`
