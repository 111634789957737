import React from 'react'
import { Text } from 'rebass'
import Layout from '../../components/Layout'
import { Container } from '../../components/styled'

import { ProjectCategories } from './project-categories'
import { ProjectsGrid } from './projects-grid'

export const ProjectsTemplate = ({ projects, categories }) => (
  <Layout>
    <Container>
      <Text as="h1" fontSize={3}>
        PROYECTOS
      </Text>
      <ProjectCategories {...categories} />
      <ProjectsGrid projects={projects} />
    </Container>
  </Layout>
)
